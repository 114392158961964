import React from 'react'
const loadText = require('src/utils').loadText
import Footer from '../../components/footer'
import { graphql } from 'gatsby'
import SEO from '../../components/SEO'
import '../../styles/lp-sdn.less'
import { ProductIntro, ProductIntroM, Customer } from '../index'
import { ProductBlog } from '../../components/block'
const static_data = loadText('lp-sdn')
const KV = () => {
  return (
    <div className="lp-sdn-kv">
      <div className="lp-sdn-kv-element">
        <div className="lp-sdn-kv-bg">
          <div className="lp-sdn-content">
            <div className="lp-sdn-kv-top">
              <img src="/lp-sdn/logo.svg" alt="logo" />
              <div className="lp-sdn-kv-description">
                {static_data.topBanner.description}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default function Index({ data }) {
  const { allStrapiPosts } = data
  return (
    <div className="lp-thanks">
      <SEO
        {...{
          title: 'Thanks | Zenlayer',
          description: 'Thank you for submittion',
        }}
        featuredImage="/featuredImage/index.jpg"
      />
      <KV />
      <div
        className="success-modal"
        style={{
          margin: '48px',
          //   borderBottom: "1px solid rgb(225, 231, 239)",
        }}
      >
        <div className="success-modal-icon">
          <img src="/check.svg" alt="success-icon" />
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: static_data.form.success }}
          className="font-20 mt-32 success-modal-content"
        />
      </div>
      <div className="only-desktop">
        <ProductIntro />
      </div>
      <div className="only-mobile">
        <ProductIntroM />
      </div>
      <Customer />
      <ProductBlog
        title={static_data.blog.title}
        // bgColor="linear-gradient(180deg,rgba(255,253,242,1) 0%,rgba(255,249,225,1) 100%)"
        blogs={allStrapiPosts.edges.map(({ node }) => node)}
      />
      <Footer />
    </div>
  )
}
export const query = graphql`
  {
    allStrapiPosts(
      filter: {
        categories: { elemMatch: { name: { eq: "Cloud Networking" } } }
        author: {}
      }
    ) {
      edges {
        node {
          slug
          thumbnail {
            localFile {
              publicURL
            }
          }
          wp_date
          categories {
            name
          }
          created_at
          content
          title
          author {
            slug
            name
          }
          excerpt
        }
      }
    }
  }
`
